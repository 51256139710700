import { Locale } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import { DEFAULT_LANGUAGE } from 'i18n/config';
import i18next from 'i18next';
import {
  LanguagePreferenceSchemaType,
  LanguagePreferences,
} from 'shared-types/user/claims/schemas/language.preference';

export const getSystemLanguageUse = (): LanguagePreferenceSchemaType => {
  const languageDetected = i18next.resolvedLanguage as LanguagePreferenceSchemaType;
  const language = LanguagePreferences[languageDetected] ? languageDetected : DEFAULT_LANGUAGE;
  return language;
};

export const getLocaleParseDateFns = (): Locale => {
  const mapLocale: Record<LanguagePreferenceSchemaType, Locale> = {
    'pt-BR': ptBR,
    en: enUS,
    es: es,
  };

  const systemLanguage = getSystemLanguageUse();
  return mapLocale[systemLanguage];
};
