import UIModule from 'components/UIModule';
import Configuration from 'configuration/configuration';
import React, { Suspense } from 'react';
import { hotjar } from 'react-hotjar';
import { Route, Router, Switch } from 'react-router-dom';
import styled from 'styled-components';
import PrivateRouteContainer from './PrivateRouteContainer';
import Loading from './SSO/animations/Loading';
import history from './history';
import ROUTES from './routes-enum';

const Login = React.lazy(() => import('./OidcLogin'));
const SlugLogin = React.lazy(() => import('./OidcLogin/SlugLogin'));
const Logout = React.lazy(() => import('./Logout'));
const Callback = React.lazy(() => import('./OidcLogin/Callback'));
const LoginError = React.lazy(() => import('./OidcLogin/LoginError'));
const LoginOnboarding = React.lazy(() => import('./OidcLogin/Onboarding'));
const RefreshUser = React.lazy(() => import('./RefreshUser'));
const SSOPage = React.lazy(() => import('./SSO'));
const SSOErrorPage = React.lazy(() => import('./SSO/error'));
const CallbackAuth = React.lazy(() => import('./OidcLogin/CallbackAuth'));

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default function App() {
  hotjar.initialize(Configuration.hotjarSiteId, 6);

  return (
    <Suspense fallback={<Loading />}>
      <UIModule />
      <Router history={history}>
        <Switch>
          <Route path={ROUTES.SIGNIN} exact component={Login} />
          <Route path={ROUTES.SLUG_SIGNIN} component={SlugLogin} />
          <Route path={ROUTES.ONBOARDING} component={LoginOnboarding} />
          <Route path={ROUTES.SSO_ERROR} component={SSOErrorPage} />
          <Route path={ROUTES.SSO} component={SSOPage} />
          <Route path={ROUTES.LOGIN_ERROR} component={LoginError} />
          <Route path={ROUTES.REFRESH_USER} component={RefreshUser} />
          <Route path={ROUTES.LOGOUT} component={Logout} />
          <Route path={ROUTES.CALLBACK} exact component={Callback} />
          <Route path={ROUTES.CALLBACK_AUTH} component={CallbackAuth} />
          <Route path="*" component={PrivateRouteContainer} />
        </Switch>
      </Router>
    </Suspense>
  );
}
