import slugifyLib from 'slugify';

export const slugify = slugifyLib;

export const getFirstLetters = name => {
  const nameList = name?.split(' ');
  const firstNameLetter = nameList[0].split('')[0];
  if (nameList.length > 1) {
    const lastNameLetter = nameList[nameList.length - 1].split('')[0];
    return (firstNameLetter ?? '') + (lastNameLetter ?? '');
  }
  return firstNameLetter;
};

export const elipsisText = (text: string, max: number) => {
  if (text.length > max) {
    return `${text.substring(0, max)}...`;
  }
  return text;
};

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator
export const validURL = (url: string) => {
  return !!urlPattern.test(url);
};

export const stringIsNullOrEmpty = (text: string): boolean => {
  return !text || String(text).trim() === '' || String(text) === 'undefined';
};

export const normalizeString = (value: string | undefined) => {
  if (!value) return '';
  return value
    ?.trim()
    .toString()
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const prettyCreditorName = ({
  creditorName,
  creditorId,
}: {
  creditorName?: string;
  creditorId: string;
}): string => {
  if (!creditorName) return creditorId;
  if (normalizeString(creditorName) === normalizeString(creditorId)) return creditorName;
  return `${creditorName} (${creditorId})`;
};

export type PrepareForComparisonOpts = {
  stripDiacritics?: boolean;
  stripPunctuation?: RegExp;
  caseInsensitive?: boolean;
  stripSpaces?: boolean;
};

export const prepareForComparison = (
  text: string,
  opts: PrepareForComparisonOpts = {
    stripDiacritics: true,
    stripPunctuation: /[.,/#!@$%&;:<>()~^{}\-+\\`´\\"[\]]/g,
    caseInsensitive: true,
    stripSpaces: true,
  },
): string => {
  if (!text) return text;
  let val = text;
  if (opts.stripDiacritics) {
    val = val
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  if (opts.stripPunctuation) {
    val = val.replace(opts.stripPunctuation, '');
  }

  if (opts.stripSpaces) {
    val = val.replace(/\s/g, '');
  }

  if (opts.caseInsensitive) {
    val = val.toLocaleLowerCase();
  }

  return val;
};

export const getInitialCharacters = (name: string | undefined) => {
  if (!name) return '';
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  const initials = [...name.matchAll(rgx)] || [];
  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
};

export const sortStrings = (firstString: string, secondString: string): number => {
  if (firstString < secondString) return -1;
  if (firstString > secondString) return 1;
  return 0;
};

type StringToBoolMap = { [key: string]: boolean };

const defaultStringToBooleanMap: StringToBoolMap = {
  sim: true,
  yes: true,
  true: true,
  verdadeiro: true,

  nao: false,
  no: false,
  false: false,
  falso: false,
};

export const customBoolDict = {
  ...defaultStringToBooleanMap,
  '1': true,
  s: true,
  y: true,
  '0': false,
  n: false,
};

export const stringAsBool = (
  value: string,
  dictOnly: boolean = true,
  normalize: boolean = true,
  dictionary: StringToBoolMap = defaultStringToBooleanMap,
): boolean => {
  const normalizedValue = normalize ? normalizeString(value) : value;
  const dictTry = dictionary[normalizedValue];
  const dictOnlyCheck = dictOnly ? false : Boolean(normalizedValue);

  // if dictOnly, returns true only if it exists on the dictionary, else it parses to bool
  // @ts-ignore
  return dictTry != null ? dictTry : dictOnlyCheck;
};

export const convertFalsyValuesToNull = <T>(str: T): T | null => {
  return !str ? null : str;
};

export const prettyLabelWithKey = (key?: string, label?: string) => {
  if (!key || !label) return key || label || '';

  const isSameValue = key.replaceAll(/_|-/g, ' ').toLowerCase() === label.toLowerCase();
  return isSameValue ? label : `${key} - ${label}`;
};
