import GlobalSpreadSheetStyle from 'components/CustomSpreadsheets/styles';
import { DefaultTheme, GlobalStyleComponent, createGlobalStyle, css } from 'styled-components';

const GlobalStyle: GlobalStyleComponent<{}, DefaultTheme> = createGlobalStyle`
    ${({ theme }) => css`
      * {
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        ::-webkit-scrollbar-track {
          background: ${theme.splitcPalette.neutral[10]};
          border-radius: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: ${theme.splitcPalette.neutral[8]};
          border-radius: 4px;
        }

        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: Roboto, sans-serif;
      }

      .htDropdownMenu:not(.htGhostTable) {
        z-index: 1400;
      }

      .htContextMenu .ht_master table.htCore {
        border: 1px solid ${theme.palette.divider};
        border-radius: 4px;
      }

      .htContextMenu table tbody tr td.htDisabled {
        background-color: ${theme.splitcPalette.neutral[9]};
        color: ${theme.splitcPalette.neutral[5]};
        font-size: ${theme.typography.body2.fontSize};
        &:hover {
          background-color: ${theme.splitcPalette.neutral[9]};
          color: ${theme.splitcPalette.neutral[5]};
        }
      }

      .htContextMenu table tbody tr td.current,
      .htContextMenu table tbody tr td.zeroclipboard-is-hover {
        background-color: ${theme.splitcPalette.neutral[10]};
      }

      .errorbadge {
        border-radius: 8px !important;
        padding: 2px !important;
        font-size: 12px !important;
        background-color: #f87f06;
        cursor: pointer;
      }

      .htContextMenu table tbody tr td {
        background-color: ${theme.splitcPalette.neutral[10]};
        color: ${theme.splitcPalette.neutral[1]};
        font-size: ${theme.typography.body2.fontSize};
        &:hover {
          background-color: ${theme.splitcPalette.neutral[9]};
          color: ${theme.palette.text.primary};
        }
      }

      .ant-select-dropdown {
        z-index: 2000;
        width: fit-content !important;
        background-color: ${theme.splitcPalette.neutral[9]};
      }

      .ant-select-item-option-content {
        color: ${theme.palette.getContrastText(theme.splitcPalette.neutral[9])} !important;
      }

      .ant-select-item-option-selected {
        background-color: ${
          theme.palette.type === 'dark'
            ? theme.splitcPalette.neutral[6]
            : theme.splitcPalette.neutral[8]
        } !important;
      }

      a {
        color: ${theme.palette.primary.main};
      }

      body {
        background-color: ${
          theme.palette.type === 'dark'
            ? theme.palette.common.black
            : theme.palette.background.default
        };
        color: ${theme.palette.text.primary};
        font-family: Roboto, sans-serif;
        overflow: auto;
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }

      ${GlobalSpreadSheetStyle({ theme })}
    `}
`;

export default GlobalStyle;
