import { getItem } from 'service/storage.service';
import {
  IMPERSONATION_ITEM_KEY,
  IMPERSONATION_OFFICE_ITEM_KEY,
  ImpersonationUser,
} from 'shared-types';

export function getImpersonatedUser() {
  try {
    const store = getItem(IMPERSONATION_ITEM_KEY);
    return store ? (JSON.parse(store) as ImpersonationUser) : null;
  } catch {
    return null;
  }
}

export function getImpersonatedOffice() {
  try {
    const store = getItem(IMPERSONATION_OFFICE_ITEM_KEY);
    return store ? store : null;
  } catch {
    return null;
  }
}
