import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const ImpersonationHeader = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.getContrastText(theme.palette.primary.main)};
  width: 100%;
  height: 1.24rem;
  z-index: 100000;
  text-align: center;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

export const CloseIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.getContrastText(theme.palette.primary.main)};
  }
`;
