import { AttachMoney, History, Payment } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import {
  CalcIcon,
  ComissionPlanIcon,
  CustomReportIcon,
  DataSource,
  ExternalLinkIcon,
  GraphIcon,
  HomeIcon,
  IconAdjustment,
  MoneyIcon,
  MonthlyReportIcon,
  PaymentIcon,
  SingleReportIcon,
  SplitCGuideIcon,
  SyncIcon,
  TeamReportIcon,
  UsersIcon,
} from 'assets/icons';
import { ManagerReportIcon } from 'assets/icons/ManageReportIcon';
import { VariableIcon } from 'assets/icons/VariblesIcon';
import i18next from 'i18next';
import ROUTES from 'pages/routes-enum';
import React from 'react';
import { generatePath } from 'react-router';
import {
  Claim,
  InferedClaimReturnType,
  ReportTypes,
  UsersClaims,
  findAndParseClaim,
} from 'shared-types';
import { useTheme } from 'styled-components';
import { useGetClaims } from 'utils/claim';

type IconProps = {
  active?: boolean;
};

export type MenuItem = {
  icon: (props: IconProps) => React.ReactNode;
  title: string;
  visible: (claims: Claim[]) => boolean;
  link?: string;
  onClick?: () => void;
  subItems?: MenuItem[];
  routesActive: string[];
  isNew?: boolean;
  endIcon?: (props: IconProps) => React.ReactNode;
  shouldTrack?: boolean;
  tooltipTitle?: string;
  disabled?: boolean;
};

const ACTIVES_CLOSURE = [
  ROUTES.CLOSURE_REPLACEMENTS,
  ROUTES.CLOSURE_GOALS,
  ROUTES.CLOSURE_NEW_GOAL,
  ROUTES.CLOSURE_EDIT_GOAL,
  ROUTES.ADJUSTMENTS_ADMIN,
  ROUTES.CLOSURE_ROOT,
  ROUTES.NEW_CLOSURE_SHEET_ORIGIN_SELECTION,
];

const reportIsHidden = (claims: Claim[], reportType: ReportTypes): boolean => {
  const { data: reportsToHideList, success } = findAndParseClaim(UsersClaims.HIDE_REPORTS, claims);
  if (!reportsToHideList || !success) return false;
  const reportsToHide: string[] = reportsToHideList.split(',').map(report => report.trim());
  return reportsToHide.includes(reportType);
};

const choosePaymentRoute = (
  findAndUseClaim: <T extends UsersClaims.FEATURE_PAYROLL_V2>(
    claim: T,
  ) => InferedClaimReturnType<T>,
): string => {
  const hasPayrollV2 = findAndUseClaim(UsersClaims.FEATURE_PAYROLL_V2).data;
  return hasPayrollV2 ? ROUTES.PAYMENT_V2 : ROUTES.PAYMENT;
};

export const useMenuItems = (): MenuItem[] => {
  const { hasClaims, hasOneOrOther, findAndUseClaim } = useGetClaims();
  const { splitcPalette, palette } = useTheme();

  const isTeamReportVisible = (claims: Claim[]): boolean => {
    const teamsReportIsHidden: boolean = reportIsHidden(claims, ReportTypes.TEAMS);
    const hasClosureUpdate: boolean = hasClaims([UsersClaims.CLOSURE_UPDATE]);
    const isDisabledCommissionTable: boolean = hasClaims([
      UsersClaims.DISABLE_ALL_COMMISSIONS_TABLE,
    ]);
    const hasTeamCreditorPermissions: boolean = hasOneOrOther([
      UsersClaims.TEAM_CREDITORS_VIEW,
      UsersClaims.TEAMS_VIEW_PERMISSION,
    ]);

    if (teamsReportIsHidden) return false;
    if (hasClosureUpdate) return hasClosureUpdate;
    if (isDisabledCommissionTable) return !isDisabledCommissionTable;
    return hasTeamCreditorPermissions;
  };

  return [
    {
      icon: ({ active }) => <HomeIcon active={active} />,
      title: i18next.t('menu.home'),
      link: hasClaims([UsersClaims.STATEMENT_PANEL_VISIBLE]) ? ROUTES.ROOT : ROUTES.ROOT_HOME,
      visible: () => true,
      routesActive: [ROUTES.ROOT],
    },
    {
      icon: ({ active }) => <SyncIcon active={active} />,
      title: i18next.t('menu.data_menu.data'),
      visible: () => true,
      routesActive: [
        generatePath(ROUTES.VARIABLES, { variableType: 'comissionado' }),
        generatePath(ROUTES.VARIABLES, { variableType: 'cliente' }),
        ROUTES.DATA_SOURCES,
      ],
      subItems: [
        {
          icon: () => <ListAltIcon />,
          title: 'Google Sheet',
          visible: () => hasClaims([UsersClaims.SHEET_DATA_SOURCE]),
          link: ROUTES.SHEET_SYNC,
          routesActive: [],
        },
        {
          icon: ({ active }) => <VariableIcon active={active} />,
          title: i18next.t('menu.data_menu.variables'),
          link: generatePath(ROUTES.VARIABLES, { variableType: 'comissionado' }),
          visible: () => hasClaims([UsersClaims.FEATURE_VARIABLES]),
          routesActive: [
            generatePath(ROUTES.VARIABLES, { variableType: 'comissionado' }),
            generatePath(ROUTES.VARIABLES, { variableType: 'cliente' }),
          ],
        },
        {
          icon: ({ active }) => <DataSource active={active} />,
          title: i18next.t('menu.data_menu.datasources'),
          link: ROUTES.DATA_SOURCES,
          visible: () => hasClaims([UsersClaims.GENERIC_DATA_IMPORT]),
          routesActive: [ROUTES.DATA_SOURCES],
        },
      ],
    },
    {
      icon: ({ active }) => <ComissionPlanIcon active={active} />,
      title: i18next.t('menu.commission_plans_menu.commission_plans'),
      visible: () =>
        hasOneOrOther([
          UsersClaims.GENERIC_DATA_IMPORT,
          UsersClaims.INQUIRIES_MANAGEMENT,
          UsersClaims.PLAN_GROUP_PERMISSIONS,
        ]),
      routesActive: [ROUTES.COMMISSION_PLAN_LIST, ROUTES.INQUIRIES],
      subItems: [
        {
          icon: ({ active }) => <ComissionPlanIcon active={active} />,
          title: i18next.t('menu.commission_plans_menu.commission_plans'),
          link: ROUTES.COMMISSION_PLAN_LIST,
          visible: () =>
            hasOneOrOther([UsersClaims.GENERIC_DATA_IMPORT, UsersClaims.PLAN_GROUP_PERMISSIONS]),
          routesActive: [ROUTES.COMMISSION_PLAN_LIST],
        },
        {
          icon: ({ active }) => (
            <QuestionAnswerIcon
              htmlColor={active ? palette.primary.main : splitcPalette.neutral[5]}
            />
          ),
          title: i18next.t('menu.commission_plans_menu.inquiries'),
          link: ROUTES.INQUIRIES,
          visible: () => hasClaims([UsersClaims.INQUIRIES_MANAGEMENT]),
          routesActive: [ROUTES.INQUIRIES],
        },
      ],
    },
    {
      icon: ({ active }) => <CalcIcon active={active} />,
      title: i18next.t('menu.closure'),
      link: ROUTES.CLOSURE_ROOT,
      visible: () => hasClaims([UsersClaims.CLOSURE_UPDATE]),
      routesActive: ACTIVES_CLOSURE,
    },
    {
      icon: ({ active }) => <UsersIcon active={active} />,
      title: i18next.t('menu.users_menu.users'),
      visible: () => hasOneOrOther([UsersClaims.USER_CREATE, UsersClaims.USER_MANAGEMENT]),
      routesActive: [
        ROUTES.USERS,
        ROUTES.PROFILES,
        ROUTES.PROFILES_CREATE,
        ROUTES.PROFILES_EDIT,
        ROUTES.DEFAULT_PROFILE,
      ],
      subItems: [
        {
          icon: ({ active }) => <UsersIcon active={active} />,
          title: i18next.t('menu.users_menu.users'),
          link: ROUTES.USERS,
          visible: () => true,
          routesActive: [ROUTES.USERS],
          tooltipTitle: i18next.t('menu.users_menu.users_tooltip'),
        },
        {
          icon: ({ active }) => <UsersIcon active={active} />,
          title: i18next.t('menu.users_menu.roles'),
          link: ROUTES.PROFILES,
          visible: () => hasClaims([UsersClaims.MANAGE_PERMISSION_PROFILES]),
          routesActive: [ROUTES.PROFILES],
        },
        {
          icon: ({ active }) => <UsersIcon active={active} />,
          title: i18next.t('menu.users_menu.upsert_customers'),
          link: ROUTES.CUSTOMERS,
          visible: () => hasClaims([UsersClaims.UPSERT_CUSTOMERS_NAME]),
          routesActive: [ROUTES.CUSTOMERS],
        },
        {
          icon: ({ active }) => (
            <GroupAddOutlinedIcon
              htmlColor={active ? palette.primary.main : splitcPalette.neutral[5]}
            />
          ),
          title: i18next.t('menu.users_menu.upsert_users'),
          link: ROUTES.BULK_USER_CREATE,
          visible: () => hasClaims([UsersClaims.USER_CREATE]),
          routesActive: [ROUTES.BULK_USER_CREATE],
        },
        {
          icon: ({ active }) => <UsersIcon active={active} />,
          title: i18next.t('menu.users_menu.impersonation_user'),
          link: ROUTES.USERS,
          visible: () => hasClaims([UsersClaims.ALLOW_USER_IMPERSONATION]),
          routesActive: [ROUTES.USER_IMPERSONATION],
          tooltipTitle: i18next.t('menu.users_menu.impersonation_user_tooltip'),
          disabled: true,
        },
      ],
    },
    {
      icon: ({ active }) => <PaymentIcon active={active} />,
      title: i18next.t('menu.payments_menu.payments'),
      visible: () => true,
      routesActive: [choosePaymentRoute(findAndUseClaim), ROUTES.DOCUMENTS],
      subItems: [
        {
          icon: ({ active }) => <PaymentIcon active={active} />,
          title: i18next.t('menu.payments_menu.companies'),
          link: ROUTES.COMPANIES,
          visible: () => hasClaims([UsersClaims.COMPANY_MANAGEMENT]),
          routesActive: [ROUTES.COMPANIES],
        },
        {
          icon: ({ active }) => <PaymentIcon active={active} />,
          title: i18next.t('menu.payments_menu.payment_groups'),
          link: ROUTES.PAYMENT_GROUPS,
          visible: () => hasClaims([UsersClaims.COMPANY_MANAGEMENT]),
          routesActive: [ROUTES.PAYMENT_GROUPS],
        },
        {
          icon: ({ active }) => <PaymentIcon active={active} />,
          title: i18next.t('menu.payments_menu.payroll'),
          link: choosePaymentRoute(findAndUseClaim),
          visible: () => hasOneOrOther([UsersClaims.PAYROLL_VIEW, UsersClaims.FEATURE_PAYROLL_V2]),
          routesActive: [choosePaymentRoute(findAndUseClaim)],
        },
        {
          icon: () => <Payment />,
          title: i18next.t('menu.payments_menu.payment_infos'),
          link: ROUTES.PAYMENT_INFO,
          visible: () => hasClaims([UsersClaims.CREDITOR_PAYMENT_INFO_VIEW]),
          routesActive: [ROUTES.PAYMENT_INFO],
        },
        {
          icon: ({ active }) => <MoneyIcon active={active} />,
          title: i18next.t('menu.payments_menu.payment_options'),
          link: ROUTES.PAYMENT_OPTIONS,
          visible: () =>
            hasClaims([UsersClaims.PERMISSION_PAYMENTS, UsersClaims.FEATURE_PAYROLL_V2]),
          routesActive: [ROUTES.PAYMENT_OPTIONS],
        },
        {
          icon: () => <AttachFileIcon />,
          title: i18next.t('menu.payments_menu.documents'),
          link: ROUTES.DOCUMENTS,
          visible: () => hasClaims([UsersClaims.DOCUMENT_MANAGEMENT]),
          routesActive: [ROUTES.DOCUMENTS],
        },
      ],
    },
    {
      icon: () => <History />,
      title: i18next.t('menu.jobs'),
      link: ROUTES.JOBS,
      visible: () => hasClaims([UsersClaims.GENERIC_DATA_IMPORT]),
      routesActive: [ROUTES.JOBS],
    },
    {
      icon: ({ active }) => <GraphIcon active={active} />,
      title: i18next.t('menu.reports_menu.reports'),
      link: '',
      visible: claims => {
        const { data: reportsToHideList, success } = findAndParseClaim(
          UsersClaims.HIDE_REPORTS,
          claims,
        );
        if (!reportsToHideList || !success) return true;
        const reportsToHide: string[] = reportsToHideList.split(',').map(report => report.trim());
        const hasCustomReport: boolean = hasClaims([UsersClaims.CUSTOM_REPORTS]);
        if (reportsToHide.length === Object.keys(ReportTypes).length && !hasCustomReport)
          return false;

        return true;
      },
      routesActive: [
        ROUTES.TEAMS_REPORTS,
        ROUTES.CUSTOM_REPORTS,
        ROUTES.NEW_OFFICE_REPORTS,
        ROUTES.CREDITOR_REPORT,
      ],
      subItems: [
        {
          title: i18next.t('menu.reports_menu.teams'),
          icon: ({ active }) => <TeamReportIcon active={active} />,
          link: ROUTES.TEAMS_REPORTS,
          routesActive: [ROUTES.TEAMS_REPORTS],
          visible: isTeamReportVisible,
        },
        {
          title: i18next.t('menu.reports_menu.custom'),
          icon: ({ active }) => <CustomReportIcon active={active} />,
          link: ROUTES.CUSTOM_REPORTS,
          visible: () => hasClaims([UsersClaims.CUSTOM_REPORTS]),
          routesActive: [ROUTES.CUSTOM_REPORTS],
        },
        {
          title: i18next.t('menu.reports_menu.management'),
          icon: ({ active }) => <ManagerReportIcon active={active} />,
          link: ROUTES.NEW_OFFICE_REPORTS,
          visible: claims => {
            if (reportIsHidden(claims, ReportTypes.OFFICE)) return false;
            return hasClaims([UsersClaims.OFFICE_CREDITORS_VIEW]);
          },
          routesActive: [ROUTES.NEW_OFFICE_REPORTS],
        },

        {
          title: i18next.t('menu.reports_menu.monthly_management'),
          icon: ({ active }) => <MonthlyReportIcon active={active} />,
          link: ROUTES.MONTHLY_MANAGEMENT_REPORTS,
          visible: claims => {
            if (reportIsHidden(claims, ReportTypes.MANAGEMENT)) return false;
            return hasClaims([UsersClaims.OFFICE_CREDITORS_VIEW]);
          },
          routesActive: [ROUTES.MONTHLY_MANAGEMENT_REPORTS],
        },
        {
          title: i18next.t('menu.reports_menu.user'),
          icon: ({ active }) => <SingleReportIcon active={active} />,
          link: ROUTES.CREDITOR_REPORT,
          visible: claims => !reportIsHidden(claims, ReportTypes.CREDITOR),

          routesActive: [ROUTES.CREDITOR_REPORT],
        },
      ],
    },
    {
      icon: ({ active }) => <IconAdjustment active={active} />,
      title: i18next.t('menu.adjustments_request'),
      link: ROUTES.ADJUSTMENTS_REQUEST,
      visible: () =>
        hasClaims([UsersClaims.ADJUSTMENT_REQUEST_VIEW, UsersClaims.TEAM_CREDITORS_VIEW]),
      routesActive: [ROUTES.ADJUSTMENTS_REQUEST],
    },
    {
      icon: ({ active }) => <SplitCGuideIcon active={active} />,
      title: i18next.t('menu.documentation'),
      link: `${window.location.origin}${ROUTES.DOCS}`,
      visible: () => !hasClaims([UsersClaims.DISABLE_SPLITC_REFERRAL]),
      routesActive: [],
      endIcon: ({ active }) => <ExternalLinkIcon active={active} />,
      shouldTrack: true,
    },
    {
      icon: () => <AttachMoney />,
      title: i18next.t('menu.indicate_splitc'),
      link: 'https://www.splitc.com.br/programa-indicacao',
      visible: () => !hasClaims([UsersClaims.DISABLE_SPLITC_REFERRAL]),
      routesActive: [],
      isNew: true,
      endIcon: ({ active }) => <ExternalLinkIcon active={active} />,
      shouldTrack: true,
    },
  ];
};
