enum EVENTS {
  LATE_CLOSURE_MODAL = 'LATE_CLOSURE_MODAL',
  MINIMUM_REVENUE_APPLY = 'MINIMUM_REVENUE_APPLY',
  MINIMUM_REVENUE_WINDOW_OPEN = 'MINIMUM_REVENUE_WINDOW_OPEN',
  PAYSLIP_EXPAND_DETAILS = 'PAYSLIP_EXPAND_DETAILS',
  REFERRAL_WHATSAPP_SHARE = 'REFERRAL_WHATSAPP_SHARE',
  REFERRAL_EMAIL_SHARE = 'REFERRAL_EMAIL_SHARE',
  CLOSURE_RATING_SAD = 'CLOSURE_RATING_SAD',
  CLOSURE_RATING_OK = 'CLOSURE_RATING_OK',
  CLOSURE_RATING_HAPPY = 'CLOSURE_RATING_HAPPY',
  CLOSURE_RATING_TEXT = 'CLOSURE_RATING_TEXT',
  LOGIN_TRY = 'LOGIN_TRY',
  APPLY_ADJUSTMENTS = 'APPLY_ADJUSTMENTS',
  APPLY_RULES = 'APPLY_RULES',
  APPLY_TAXES = 'APPLY_TAXES',
  MENU_CLICK = 'MENU_CLICK',
  PAGE_VIEW = 'PAGE_VIEW',
  SYNC_DATASOURCE = 'SYNC_DATASOURCE',
  SYNC_PLAN = 'SYNC_PLAN',
  PLAN_CREATE = 'PLAN_CREATE',
  PLAN_PERIOD_CREATE = 'PLAN_PERIOD_CREATE',
  PLAN_DUPLICATE = 'PLAN_DUPLICATE',
  PLAN_DELETE = 'PLAN_DELETE',
  PLAN_ARCHIVE = 'PLAN_ARCHIVE',
  PLAN_PERIOD_VISIBILITY = 'PLAN_PERIOD_VISIBILITY',
  STATEMENT_DASHBOARD_SAVE = 'STATEMENT_DASHBOARD_SAVE',
  FORMULA_SAVE = 'FORMULA_SAVE',
  IMPERSONATE_USER = 'IMPERSONATE_USER',
  UPSERT_DATASOURCE = 'UPSERT_DATASOURCE',
  UPSERT_DATASOURCES = 'UPSERT_DATASOURCES',
  CLOSURE_VISIBLE_TRUE = 'CLOSURE_VISIBLE_TRUE',
  CLOSURE_FILE_UPLOAD = 'CLOSURE_FILE_UPLOAD',
  APPLY_REPLACEMENT_FIELDS = 'APPLY_REPLACEMENT_FIELDS',
  DASHBOARD_DOCUMENT_UPLOAD = 'DASHBOARD_DOCUMENT_UPLOAD',
  PAYROLL_DOCUMENT_UPLOAD = 'PAYROLL_DOCUMENT_UPLOAD',
  DOCUMENTS_APPROVE = 'DOCUMENTS_APPROVE',
  DOCUMENTS_REJECT = 'DOCUMENTS_REJECT',
}

export default EVENTS;
