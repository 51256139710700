import { AxiosRequestConfig } from 'axios';
import { getImpersonatedOffice, getImpersonatedUser } from 'modules/user/impersonation';
import { IMPERSONATE_USER_HEADER, IMPERSONATE_USER_OFFICE_ID_HEADER } from 'shared-types';
import { v4 as uuidv4 } from 'uuid';

export class AxiosUtils {
  static injectCorrelationId(config: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig = { ...config };
    newConfig.headers['x-correlation-id'] = uuidv4();
    return newConfig;
  }

  static injectImpersonationUser(config: AxiosRequestConfig) {
    const impersonationUser = getImpersonatedUser();
    if (impersonationUser) {
      config.headers[IMPERSONATE_USER_HEADER] = impersonationUser.externalCreditorId;
    }

    const impersonatedOffice = getImpersonatedOffice();
    if (impersonationUser && impersonatedOffice) {
      config.headers[IMPERSONATE_USER_OFFICE_ID_HEADER] = impersonatedOffice;
    }

    return config;
  }

  static getFilenameFromContentDisposition(headerVal: string): string | undefined {
    if (!headerVal.includes('filename')) return undefined;
    const split = headerVal.split('filename=');
    return split[1];
  }

  static convertArrayBufferToJson<R>(buff: ArrayBuffer): R {
    const data = new TextDecoder('utf-8').decode(buff);
    return JSON.parse(data);
  }
}
