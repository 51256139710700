import { Avatar, Button, Grid } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ChangeOffice } from 'assets/icons';
import features from 'features';
import { useFeatures } from 'hooks/feature';
import useSelector from 'modules/default-selector';
import history from 'pages/history';
import ROUTES from 'pages/routes-enum';
import React, { useState } from 'react';

import { OfficeLogo } from 'components/OfficeLogo';
import { getInitialCharacters } from 'utils/text';
import {
  IconChangeOffice,
  ItemDropDown,
  Line,
  LogoContainer,
  StyledMenu,
  TextDropDown,
  TextNameOffice,
  Wrapper,
  WrapperIcon,
  WrapperLine,
} from './styles';

type Props = {
  isOpened: boolean;
  toggleCollapseMenu: () => void;
};

const Header = ({ isOpened, toggleCollapseMenu }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const feature = useFeatures();
  const backofficePermissions = feature.backoffice;
  const userClaims = useSelector(state => state.user.claims);
  const officeName = useSelector(state => state.user.escritorio?.nome_escritorio);

  const logo = features.imageLogo(userClaims);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleRedirect = (route: string) => {
    handleClose();
    history.push(route);
  };

  const componentHeaderLogo = () => {
    if (isOpened && logo) {
      return <OfficeLogo src={logo} alt={officeName} />;
    }

    return isOpened ? (
      <TextNameOffice>{officeName}</TextNameOffice>
    ) : (
      <Grid container justifyContent="center">
        <Avatar>{getInitialCharacters(officeName)}</Avatar>
      </Grid>
    );
  };

  return (
    <Wrapper $isOpened={isOpened}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        disableElevation
        disableFocusRipple
        disableRipple
        style={{
          backgroundColor: 'transparent',
          padding: 0,
          width: '100%',
          cursor: backofficePermissions ? 'pointer' : 'auto',
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent={isOpened ? 'space-between' : 'center'}
          onClick={backofficePermissions ? handleClick : () => null}
        >
          <LogoContainer
            container
            justifyContent="center"
            item
            xs={backofficePermissions ? 10 : 12}
          >
            {componentHeaderLogo()}
          </LogoContainer>

          {backofficePermissions && isOpened && (
            <IconChangeOffice>
              {anchorEl ? (
                <KeyboardArrowUpIcon fontSize="small" style={{ color: '#ABABAB' }} />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" style={{ color: '#ABABAB' }} />
              )}
            </IconChangeOffice>
          )}
        </Grid>
      </Button>

      <StyledMenu
        id="customized-menu"
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {backofficePermissions && (
          <ItemDropDown onClick={() => handleRedirect(ROUTES.BACKOFFICE_ROOT)}>
            <ChangeOffice />
            <TextDropDown>Backoffice</TextDropDown>
          </ItemDropDown>
        )}
      </StyledMenu>

      <WrapperLine
        container
        alignItems="center"
        justifyContent={isOpened ? 'space-between' : 'center'}
      >
        {isOpened && (
          <Grid item xs={10}>
            <Line />
          </Grid>
        )}

        <WrapperIcon data-testId="toggle-sidemenu" onClick={toggleCollapseMenu}>
          {isOpened ? (
            <KeyboardArrowLeftIcon fontSize="inherit" />
          ) : (
            <KeyboardArrowRightIcon fontSize="inherit" />
          )}
        </WrapperIcon>
      </WrapperLine>
    </Wrapper>
  );
};

export default Header;
