import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const AgreementsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
  gap: 0.25rem;
  padding: 0.2rem;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;

export const Link = styled(RouterLink)`
text-decoration: underline;
color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
