import { ONE_MINUTE_STALE_TIME } from 'constants/reactQuery';
import useSelector from 'modules/default-selector';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'service/global/types';
import { ListPacketsResponse, PacketTypeEnum, UsersClaims } from 'shared-types';
import { useGetClaims } from 'utils/claim';
import Backend from '..';
import { makePolicyAgreementsToSignKeys } from './keys';

export const useGetPolicyAgreementsToSign = (opts?: UseQueryOptions<ListPacketsResponse>) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);
  const { hasClaims } = useGetClaims();
  return useQuery(
    makePolicyAgreementsToSignKeys(officeId),
    async (): Promise<ListPacketsResponse> => {
      const { data } = await Backend.get(
        `/offices/${officeId}/document-signer/packets/${PacketTypeEnum.POLICY_AGREEMENT}/to-sign`,
      );
      return data;
    },
    {
      enabled: !!officeId && hasClaims([UsersClaims.POLICY_AGREEMENT_MODULE]),
      staleTime: ONE_MINUTE_STALE_TIME * 5,
      refetchOnWindowFocus: true,
      ...opts,
    },
  );
};
