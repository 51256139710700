import { createAction, createReducer } from 'redux-act';
import { fetchClosures, updateClosureState } from 'service/closure.service';
import { CloseMonthRequest, Fechamento, GetClosuresResponse } from 'shared-types';
import { errorStore } from 'stores/errorStore';
import { formatUtc } from 'utils/date';

import { getSelectedClosure } from './domonthclosure.selector';
import { DefaultThunk } from './typings';

export type MonthClosureState = {
  loading: boolean;
  closureMonth?: string;
  origin?: string;
  remoteSheetUrl?: string;
  sheetFileName?: string;
  officeClosures?: GetClosuresResponse;
  additionalData?: CloseMonthRequest['additional_data'];
};

const initialState: MonthClosureState = {
  loading: false,
};

export const setOrigin = createAction<string>('commission/MONTHCLOSURE/SET_ORIGIN');
export const setOfficeClosures = createAction<GetClosuresResponse>(
  'commission/MONTHCLOSURE/OFFICE_CLOSURES',
);
export const setRemoteFileLocation = createAction<string | undefined>(
  'commission/MONTHCLOSURE/REMOTE_FILE',
);

export const setFileName = createAction<string | undefined>('commission/MONTHCLOSURE/FILE_NAME');
export const resetClosure = createAction('commission/MONTHCLOSURE/RESET');
export const setLoading = createAction<boolean>('commission/MONTHCLOSURE/SET_LOADING');
export const additionalDataSet = createAction<undefined | CloseMonthRequest['additional_data']>(
  'commission/MONTHCLOSURE/ADDITIONAL_DATA_SET',
);
export const clearClosure = createAction('commission/MONTHCLOSURE/CLEAR');
export const setClosureDate = createAction<Date, { date: string }>(
  'commission/MONTHCLOSURE/MONTH',
  date => ({
    date: formatUtc(date),
  }),
);

const reducer = createReducer<MonthClosureState>({}, initialState);

reducer.on(setOrigin, (state, payload: string) => ({
  ...state,
  origin: payload,
}));

reducer.on(additionalDataSet, (state, payload) => ({
  ...state,
  additionalData: payload,
}));

reducer.on(setOfficeClosures, (state, payload: GetClosuresResponse) => ({
  ...state,
  officeClosures: payload,
}));

reducer.on(setRemoteFileLocation, (state, payload) => ({
  ...state,
  remoteSheetUrl: payload,
}));

reducer.on(clearClosure, () => ({
  ...initialState,
}));

reducer.on(setLoading, (state, payload) => ({
  ...state,
  loading: payload,
}));

reducer.on(setFileName, (state, payload) => ({
  ...state,
  sheetFileName: payload,
}));

reducer.on(setClosureDate, (state, payload) => ({
  ...state,
  closureMonth: payload.date,
}));

reducer.on(resetClosure, () => ({
  ...initialState,
}));

export const fetchOfficeClosures = (): DefaultThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { user } = getState();

    if (!user.escritorio?.id_escritorio)
      throw new Error('Cant fetch closures without state.user.escritorio.idEscritorio');

    const closures = await fetchClosures(String(user.escritorio.id_escritorio));

    dispatch(setOfficeClosures(closures));
  } catch (error) {
    errorStore.errorSink(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const toggleClosureRelease =
  (onChange?: (closure: Fechamento) => void): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { escritorio } = state.user;
    const { selectedDate } = state.commission;

    const selectedClosure = getSelectedClosure(state);

    try {
      const closure = await updateClosureState({
        change: { liberado: Boolean(!selectedClosure?.visible) },
        officeId: Number(escritorio?.id_escritorio),
        date: String(selectedDate),
      });

      dispatch(fetchOfficeClosures());

      if (onChange) {
        onChange(closure);
      }
    } catch (error) {
      errorStore.errorSink(error);
    }
  };

export default reducer;
