import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LanguagePreferenceSchemaType } from 'shared-types/user/claims/schemas/language.preference';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import ptBr from './locales/ptBr/translation.json';

export const DEFAULT_LANGUAGE: LanguagePreferenceSchemaType = 'pt-BR';

const resources: Record<LanguagePreferenceSchemaType, ResourceLanguage> = {
  'pt-BR': {
    translation: ptBr,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

const fallbackLng: Record<string, LanguagePreferenceSchemaType[]> = {
  pt: ['pt-BR'],
  'pt-*': ['pt-BR'],
  default: [DEFAULT_LANGUAGE],
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
  cache: ['localStorage', 'cookie'],
});

export default i18n;
