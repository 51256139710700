import { setOfficeClosures } from 'modules/domonthclosure';
import { createAction, createReducer } from 'redux-act';
import { putRecurrentRevenue } from 'service/commission.service';
import {
  ApplyRulesRequest,
  CommissionResponse,
  InsertCommissionRequest,
  ListPreference,
  OFFICE_RESERVED_CREDITOR_ID,
  PreferencesResponseV2,
  SalaryPreference,
  TaxesPreference,
} from 'shared-types';
import { now } from 'utils/date';
import { handleDefaultErrors, isAxiosError } from 'utils/error';
import { showInfoToast } from 'utils/toast';
import { QueryPrefixes, queryClient } from '../service/query.client';
import { fetchCommissions } from './commission';
import { invalidateCreditorQueries } from './common';
import { DefaultThunk } from './typings';
import { showErrorModal } from './ui';
import { refreshCreditorList } from './user';

export const setAdjustments = createAction<InsertCommissionRequest[]>(
  'commission/RULES/ADJUSTMENTS',
);
export const setList = createAction<{ name: string; items: string[] }>('commission/RULES/LISTS');
export const setAllLists = createAction<ListPreference>('commission/RULES/ALL_LISTS');

export const setRecurrentRevenue = createAction<SalaryPreference[]>(
  'commission/RULES/RECURRENT_REVENUE',
);

export const setDraft = createAction<boolean>('commission/RULES/SET_DRAFT');
export const setListDraft = createAction<boolean>('commission/RULES/SET_LIST_DRAFT');
export const setTaxes = createAction<Taxes[]>('commission/RULES/SET_TAXES');

export interface Taxes extends TaxesPreference {}

const OFFICE_RESERVED_CREDITOR = OFFICE_RESERVED_CREDITOR_ID ?? 'ESCRITORIO';

export interface RulesState {
  adjustments: InsertCommissionRequest[];
  recurrentRevenue: SalaryPreference[];
  changeCustomerCreditor: ApplyRulesRequest['change_customers_creditor'];
  deriveProfit: ApplyRulesRequest['derive_customers_profit'];
  deriveProductProfit: ApplyRulesRequest['derive_products_profit'];
  deriveCreditorsProfit: ApplyRulesRequest['derive_creditors_profit'];
  customRules: ApplyRulesRequest['custom_rules'];
  taxes: Taxes[];
  draft: boolean;
  listDraft: boolean;
}

const initialState: RulesState = {
  adjustments: [],
  recurrentRevenue: [],
  changeCustomerCreditor: { changes: [] },
  deriveProfit: { apply_on_negatives: false, changes: [] },
  deriveProductProfit: { apply_on_negatives: false, changes: [] },
  deriveCreditorsProfit: { apply_on_negatives: false, changes: [] },
  customRules: { changes: [] },
  taxes: [],
  draft: false,
  listDraft: false,
};

const reducer = createReducer<RulesState>({}, initialState);

reducer.on(
  setDraft,
  (state, payload): RulesState => ({
    ...state,
    draft: payload,
  }),
);
reducer.on(
  setListDraft,
  (state, payload): RulesState => ({
    ...state,
    listDraft: payload,
  }),
);

reducer.on(
  setAdjustments,
  (state, payload): RulesState => ({
    ...state,
    adjustments: payload,
  }),
);

reducer.on(
  setRecurrentRevenue,
  (state, payload): RulesState => ({
    ...state,
    recurrentRevenue: payload,
  }),
);

reducer.on(
  setTaxes,
  (state, payload): RulesState => ({
    ...state,
    taxes: payload,
  }),
);

const getCreditorId = (adjustment: CommissionResponse): string => {
  const creditorId =
    adjustment.divisions?.find(
      division => division?.creditor?.external_creditor_id !== OFFICE_RESERVED_CREDITOR,
    )?.creditor.external_creditor_id || '';

  return creditorId;
};

export const mapToCommisionRequest = (
  adjustments: CommissionResponse[],
): InsertCommissionRequest[] => {
  return adjustments?.map(adjustment => {
    const creditorId = getCreditorId(adjustment);

    const divisions = adjustment.divisions?.filter(
      division => division?.creditor?.external_creditor_id === OFFICE_RESERVED_CREDITOR,
    );

    const insertRequest: InsertCommissionRequest = {
      ...adjustment,
      grantee_creditor_id: creditorId,
      external_creditor_id: creditorId,
      divisions: [],
    };

    if (divisions[0]?.creditor?.external_creditor_id) {
      insertRequest.divisions = [
        {
          external_creditor_id: divisions[0]?.creditor?.external_creditor_id,
          percentage: divisions[0]?.percentage,
        },
      ];
    }

    return insertRequest;
  });
};

export const localClousureUpdate =
  (selectedDate, modifications): DefaultThunk =>
  (dispatch, getState) => {
    const { monthclosure } = getState();
    const closures = monthclosure.officeClosures;
    const updatedClosures = closures?.map(closure => {
      if (closure.closure_date === selectedDate) {
        return { ...closure, ...modifications };
      }
      return closure;
    });
    if (updatedClosures) dispatch(setOfficeClosures(updatedClosures));
  };

export const applyRecurrentRevenue = (): DefaultThunk => async (dispatch, getState) => {
  const state = getState();

  const { impersonatedUser } = state.user;
  const { selectedDate } = state.commission;
  const { recurrentRevenue } = state.rules;

  try {
    if (!impersonatedUser?.officeId)
      throw new Error('Tried to applyAdjustments() without state.impersonatedUser.officeId');
    if (!selectedDate)
      throw new Error('Tried to applyAdjustments() without state.commission.selectedDate');

    await putRecurrentRevenue({
      officeId: impersonatedUser.officeId,
      commissionDate: selectedDate,
      recurrentRevenue,
    });

    dispatch(localClousureUpdate(selectedDate, { data_atualizacao: now() }));

    dispatch(refreshCreditorList({ closureDate: selectedDate }));
    dispatch(fetchCommissions());
    dispatch(setDraft(false));
    invalidateCreditorQueries();
    queryClient.setQueryData(
      [QueryPrefixes.GET_PREFERENCES, impersonatedUser.officeId, selectedDate],
      (old: Partial<PreferencesResponseV2> | undefined) => ({
        ...old,
        salary: recurrentRevenue,
      }),
    );
  } catch (error) {
    if (isAxiosError(error) && error?.response?.status === 400) {
      dispatch(
        showErrorModal({
          body: 'Verifique se todas as datas de validade estão corretamente preenchidas',
        }),
      );
      return;
    }
    handleDefaultErrors(error);
  }
};

export const applyTaxes =
  (apply, taxes): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const { impersonatedUser } = state.user;
    const { selectedDate } = state.commission;

    try {
      if (!impersonatedUser?.officeId)
        throw new Error('Tried to applyTaxes() without state.impersonatedUser.officeId');
      if (!selectedDate)
        throw new Error('Tried to applyTaxes() without state.commission.selectedDate');

      await apply({
        officeId: String(impersonatedUser.officeId),
        closureDate: selectedDate,
        taxes,
      });

      dispatch(localClousureUpdate(selectedDate, { data_atualizacao: now() }));

      dispatch(setDraft(false));
      showInfoToast('Impostos aplicados com sucesso');
      dispatch(fetchCommissions());
      invalidateCreditorQueries();
      queryClient.invalidateQueries(QueryPrefixes.GET_PREFERENCES);
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 400) {
        dispatch(
          showErrorModal({
            body: 'Verifique se todas linhas estão preenchidas corretamente',
          }),
        );
        return;
      }

      handleDefaultErrors(error);
    }
  };

export default reducer;
