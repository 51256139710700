import styled from 'styled-components';

const LateralPadding = styled.div<{ $amount?: number; $height?: string }>`
  padding: ${props => {
    return `${props?.$amount ?? 30}px ${props?.$amount ?? 30}px ${
      props?.$amount ?? 30
    }px ${props?.$amount ?? 30}px`;
  }};
  height: ${({ $height }) => $height};
`;

export default LateralPadding;
